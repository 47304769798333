<template>
  <div class="py--xl">
    <getecma-header size="md">Desculpe!</getecma-header>
    <getecma-header>Permissão negada</getecma-header>
    <p>
      Você não possui acesso à essa página ou sua sessão expirou
    </p>
    <getecma-button class="btn btn-primary" @click="goToLoginPage()">
      Voltar à tela de login
    </getecma-button>
  </div>
</template>

<script>
import { goToLoginPage } from '@/router/route.service';

export default {
  name: 'GetecmaForbiddenPage',
  methods: {
    goToLoginPage,
  },
};
</script>
